.loading-background {
    width: 100vw;
    height: 100vh;
    background: #17252A;
    display: grid;
}
.loading-text {
    color: #DEF2F1;
    justify-self: center;
    align-self: center;
    animation-name: loading-bounce;
    animation-duration: 1.5s;
    animation-fill-mode: both; 
    animation-timing-function: ease-in-out; 
    animation-iteration-count:infinite; 
}

@keyframes loading-bounce { 
    0%, 20%, 50%, 80%, 100% {transform: translateY(0);} 
    40% {transform: translateY(-30px);} 
    60% {transform: translateY(-15px);} 
} 