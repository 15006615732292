
:root{
  --main-1: #040404;
  --main-2: #19171a;
  --main-3: #252525;
  --main-4: rgb(187, 189, 204);
  --secondary-dark: #575764;
  --secondary-light: #d9dae6;
  --highlight-main: #dd1d11;
  --highlight-main2: #e9322e;
  --highlight-2: #ceafae;
  --highlight-3: #464898;
  --highlight-4: #daa522; 

}

html,
body {
  width: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

.App {
  text-align: center;
}
.nav-offset {
  height: 10vh;
  grid-row: 1/2;
  width: 100%;
}
.main-container {
  display: grid;
  grid-template-rows: repeat(3, minmax(auto, max-content));
  min-height: 100vh;
  background-color: #4da8da;
}
.main-body {
  display: grid;
  min-height: 80vh;
  max-width: 100vw;
  grid-row: 2/3;
  /* found at heropatterns.com */
  background-color: #000000;
  background-image: url("./components/mainbg.svg");
  background-attachment: fixed;
  background-size: cover;;
}

@media only screen and (max-width: 768px) {
  .nav-offset {
    height: 11vh;
  }
}
